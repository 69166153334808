import styled from 'styled-components'

export default styled.div`
    position: relative;
    
    .text-editor {
        width: ${props => props.width ? props.width : "auto"};
        transition: border 0.3s;
        padding: 16px;
        box-sizing: border-box;
        height: ${props => props.height};
      
        > div:nth-child(2) {
            margin-top: 16px;
        }
    }
`
