import styled, { keyframes } from 'styled-components'

const errorPulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(245, 71, 58, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(245, 71, 58, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(245, 71, 58, 0);
    }
`;


export default styled.div`
    position: absolute;
    right: 26px;
    bottom: 26px;

    i {
        border-radius: 100%;
        animation: ${errorPulse} 2s infinite;
        color: #f44336;
    }
`