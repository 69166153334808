import styled from "styled-components"

export default styled.div`
    div.DraftEditor-root {
        height: ${props => `${props.readOnly ? (props.height - 32) : (props.height - 88)}px`} !important;
    }

  .DraftEditor-editorContainer {
      overflow-y: auto;
  }
`
