import React, { Component } from "react"
import { RichUtils, EditorState } from "draft-js"
import "draft-js/dist/Draft.css"
import Editor from "draft-js-plugins-editor"
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin"
import "draft-js-static-toolbar-plugin/lib/plugin.css"
import "draft-js/dist/Draft.css"
import SavedIndicator       from "./styled/SavedIndicator"
import LoadingSpinner       from "./styled/LoadingSpinner"
import ErrorIndicator       from "./styled/ErrorIndicator"
import TextEditorParent       from "./styled/TextEditorParent"
import TextEditorGroup from './styled/TextEditorGroup'

import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton
} from 'draft-js-buttons'

import { stateFromHTML } from "draft-js-import-html"
import { stateToHTML } from "draft-js-export-html"

export default class extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyCommand = this.handleKeyCommand.bind(this)
        this.getEditorStateFromHTML = this.getEditorStateFromHTML.bind(this)

        this.state = { status: 0, focus: false, edited: false, editorState: this.getEditorStateFromHTML(props.value) }

        const staticToolbarPlugin = createToolbarPlugin()
        const { Toolbar } = staticToolbarPlugin
        const plugins = [staticToolbarPlugin]

        this.Toolbar = Toolbar
        this.plugins = plugins
    }

    getEditorStateFromHTML(raw) {
        return EditorState.createWithContent(stateFromHTML(raw || ""))
    }

    handleChange() {
        if(!this.state.edited) return false

        const value = stateToHTML(this.state.editorState.getCurrentContent())

        if(this.props.onChange) this.props.onChange(value)
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command)

        if(newState) {
            this.handleChange(newState)

            return "handled"
        }

        return "not-handled"
    }

    render = () => {
        const Toolbar = this.Toolbar
        const standardHeight = 300

        return <React.Fragment>
            <TextEditorGroup focus={this.state.focus} disabled={this.props.disabled} width={this.props.width} height={this.props.height || standardHeight}>
                <TextEditorParent readOnly={this.props.readOnly} height={this.props.height || standardHeight}>
                    <div className="text-editor" spellCheck="false" style={{ height: this.props.height || standardHeight }}>
                        {this.state.status === 1 && <LoadingSpinner className="status-icon"><i className="far fa-sync fa-spin"></i></LoadingSpinner>}
                        {this.state.status === 2 && <SavedIndicator className="status-icon"><i className="far fa-check-circle"></i></SavedIndicator>}
                        {this.state.status === 3 && <ErrorIndicator className="status-icon"><i className="far fa-exclamation-circle"></i></ErrorIndicator>}
                        {this.state.editorState && <Editor placeholder={this.props.placeholder} plugins={this.plugins} handleKeyCommand={this.handleKeyCommand} editorState={this.state.editorState} disabled={this.props.disabled} readOnly={this.props.readOnly || this.props.disabled} onFocus={ () => this.setState({ focus: true }) } onChange={editorState => {
                            this.setState({ editorState, edited: stateToHTML(editorState.getCurrentContent()) !== stateToHTML(this.state.editorState.getCurrentContent()) })
                        }} onBlur={() => {
                            this.handleChange()
                            this.setState({ focus: false, edited: false })
                        }} />}
                        {!this.props.disabled && !this.props.readOnly && <Toolbar>
                            {externalProps => <>
                                <BoldButton {...externalProps} />
                                <ItalicButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <Separator {...externalProps} />
                                <UnorderedListButton {...externalProps} />
                                <OrderedListButton {...externalProps} />
                            </>}
                        </Toolbar>}
                    </div>
                </TextEditorParent>
            </TextEditorGroup>
        </React.Fragment>
    }
}
