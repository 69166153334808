import styled from 'styled-components'

export default styled.div`
    position: absolute;
    right: 26px;
    bottom: 26px;
    
    i {
        color: #1abc9c;
    }
`