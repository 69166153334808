import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"
import i18next from "i18next";
import {Grid, GridElement, Input, TextEditor, Select} from "../../../../components";

export default class extends Component {
    state = { provisionInterval: null, serviceProviders: [], loading: false, email: null, serviceProviderId: null, title: null, text: null }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            contactEmail
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ serviceProviders: data.data.ServiceProviders.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={provisionInterval => {
            console.log(provisionInterval)

            this.setState({
                provisionInterval,
                serviceProviderId: String(provisionInterval.serviceProvider.id),
                email: provisionInterval.serviceProvider.contactEmail || null,
                loading: false,
                title: "Schriftliche Erinnerung an Leistungszyklus",
                text: `
                    Sehr geehrte Damen und Herren,<br /><br />
                    hiermit erinnern wir Sie an die fristgemäße Erfüllung des vereinbarten Leistungszyklus.<br /><br />
                    Nach unserer Berechnung lautet der Fälligkeitstermin: <b>${provisionInterval.raw.date}</b>.<br /><br />
                    <b>Vereinbarter Intervall:</b> ${provisionInterval.interval}<br />
                    <b>Name der Leistung:</b> ${provisionInterval.name}<br />
                    <b>Vertrag:</b> ${provisionInterval.contract}<br /><br />
                    <b>Objektanschrift:</b><br />
                    ${provisionInterval.subsidiary}<br />
                    ${provisionInterval.raw.subsidiary.streetName} ${provisionInterval.raw.subsidiary.streetNumber}<br />
                    ${provisionInterval.raw.subsidiary.postalCode} ${provisionInterval.raw.subsidiary.city}<br /><br />
                    Wir bitten um Zusendung eines vorläufigen Leistungstermins.<br /><br />
                    Mit freundlichen Grüßen<br />
                    Max Mustermann
                `
            })
        }}>
            <Modal.Head title={i18next.t("page.private.contracts.remindServiceProviderModal.title")} icon="history" />
            <Modal.Body padding={24}>
                <Grid gap="16px">
                    <GridElement>
                        <Select readOnly label={i18next.t("page.private.measure.createMeasureOfferRequestModal.serviceProvider")} adjustWidth required icon="user-tie" items={this.state.serviceProviders.map(serviceProvider => ({
                            key: serviceProvider.id,
                            title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name,
                            contactEmail: serviceProvider.contactEmail
                        }))} value={this.state.serviceProviderId} />
                    </GridElement>
                    <GridElement>
                        <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.cancelMeasureOfferRequestModal.email")} icon="envelope" placeholder={i18next.t("page.private.measure.cancelMeasureOfferRequestModal.searchPlaceholderMustermann")} value={this.state.email} onChange={email => this.setState({ email })} required />
                    </GridElement>
                    <GridElement>
                        <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.cancelMeasureOfferRequestModal.subject")} icon="heading" placeholder={i18next.t("page.private.measure.cancelMeasureOfferRequestModal.searchPlaceholderPo2023")} value={this.state.title} onChange={title => this.setState({ title })} required />
                    </GridElement>
                    <GridElement>
                        <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                            <TextEditor
                                readOnly={this.state.loading}
                                label={i18next.t("page.private.measure.cancelMeasureOfferRequestModal.Receiver")}
                                icon="font"
                                placeholder={i18next.t("page.private.measure.cancelMeasureOfferRequestModal.searchPlaceholderGreetings")}
                                value={this.state.text}
                                height={500}
                                onChange={text => this.setState({ text })}
                            />
                        </div>
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.email || !this.state.serviceProviderId || !this.state.title || !this.state.text} thick title={i18next.t("page.private.contracts.remindServiceProviderModal.sendButton")} icon="envelope" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($email: String!, $title: String!, $text: String!, $serviceProviderId: ID!, $provisionIntervalId: ID!, $subsidiaryId: ID!) {
                            remindServiceProviderProvisionInterval(email: $email, title: $title, text: $text, serviceProviderId: $serviceProviderId, provisionIntervalId: $provisionIntervalId, subsidiaryId: $subsidiaryId)
                        }
                    `,
                    variables: {
                        email: this.state.email,
                        title: this.state.title,
                        text: this.state.text,
                        provisionIntervalId: this.state.provisionInterval.id,
                        subsidiaryId: this.state.provisionInterval.raw.subsidiary.id,
                        serviceProviderId: this.state.serviceProviderId
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.remindServiceProviderProvisionInterval) {
                        instance.close()
                        this.props.refresh()
                    }
                })}
            } />} />
        </Modal>
    }
}
