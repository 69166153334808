import u2fApi from "u2f-api"
import Cookies from "universal-cookie"

export default function() {
    this.setState({ loading: true })

    fetch(scanmetrix.backendURL + "/user/authentication", {
        method: "POST",
        body: JSON.stringify({
            email: this.state.email,
            password: this.state.password
        }),
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        }
    }).then(result => result.json()).then(result => {
        if(result.challenge) {
            this.setState({ blurMode: "u2f-verification", loading: false })

            u2fApi.sign(result).then(authResponse => {
                if(this.state.blurMode === "u2f-verification") {
                    fetch(scanmetrix.backendURL + "/user/u2f-authentication-verification", {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify({
                            authRequest: result,
                            authResponse
                        }),
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }).then(result => result.json()).then(result => {
                        if(result.success) {
                            this.props.refreshSession(() => this.props.history.push("/dashboard"))
                        } else {
                            this.setState({ blurMode: null })
                        }
                    })
                }
            }).catch(() => this.setState({ blurMode: null }))
        } else {
            const status = result.status

            if(status === "LOGIN_SUCCESSFUL") {
                new Cookies().set("rememberUsername", this.state.remember ? this.state.email : "", { path: "/", maxAge: 60 * 60 * 24 * 365 })
                this.props.refreshSession(() => this.props.history.push("/dashboard"))
            } else if(status === "ERROR") {
                this.setState({ loading: false, blurMode: "error", error: result.error })
            }
        }
    })
}
