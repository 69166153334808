import React, { Component } from "react"
import { Page, Button, Breadcrumbs, GridElement, Select, Section, Table } from "scanmetrix-components"
import { withRouter } from "react-router-dom"
import Board from 'react-trello'
import styled from "styled-components"
import CreateTicketModal from "./modals/CreateTicketModal"

import UpdateTicketModal from "./modals/UpdateTicketModal";
import AssignTicketModal from "./modals/AssignTicketModal";
import Avatar from "react-avatar";
import DeleteTicketModal from "./modals/DeleteTicketModal";
import i18next from "i18next";
import Cookies from "js-cookie"

const StyledMenuBar = styled.div`
    width: 100%;
    margin-bottom: 24px;
`

class MenuBar extends Component {
    render() {
        const {
            subsidiaryId,
            ticketEscalationStepId,
            priority,
            userId,
            type,
            channelType,
            buildingInspectionType,
            buildingInspectionTemplateId,
            types
        } = this.props;

        return <StyledMenuBar>
            <GridElement styled padding="16px" title={i18next.t("page.private.ticket-system.tickets.gridElementTitle")} icon="filter" rightContent={<Button disabled={scanmetrix.checkPermission("Ticket") < 2} title={i18next.t("page.private.ticket-system.tickets.button")} primary thick icon="comments-alt" onClick={this.props.onCreate} />}>
                <div style={{ display: "grid", gridGap: 16, gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                    <Select
                        adjustWidth
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.branch")}
                        icon="map-marker"
                        value={subsidiaryId}
                        onChange={subsidiaryId => {
                            this.props.onChangeSubsidiary(subsidiaryId);
                        }}
                        items={this.props.subsidiaries.map(subsidiary => ({
                            key: subsidiary.id,
                            title: `${subsidiary.name} (${subsidiary.label || subsidiary.id}) / ${subsidiary.address.inline}`
                        }))}
                    />
                    <Select
                        adjustWidth
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.ticketEscalationStep")}
                        icon="message-exclamation"
                        value={ticketEscalationStepId}
                        onChange={ticketEscalationStepId => {
                            this.props.onChangeTicketEscalationStep(ticketEscalationStepId);
                        }}
                        items={this.props.ticketEscalationSteps.map(step => ({
                            key: step.id,
                            title: step.description
                        }))}
                    />

                    <Select
                        adjustWidth
                        icon="exclamation"
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.priority")}
                        value={priority}
                        onChange={priority => {
                            this.props.onChangePriority(priority);
                        }}
                        items={[
                            { key: "low", title: i18next.t("page.private.ticket-system.tickets.searchPlaceholder.priorityTypes.low") },
                            { key: "medium", title: i18next.t("page.private.ticket-system.tickets.searchPlaceholder.priorityTypes.medium") },
                            { key: "high", title: i18next.t("page.private.ticket-system.tickets.searchPlaceholder.priorityTypes.high") }
                        ]}
                    />
                    <Select
                        adjustWidth
                        icon="user"
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.user")}
                        value={userId}
                        onChange={userId => {
                            this.props.onChangeUserId(userId);
                        }}
                        items={this.props.users.map(user => ({
                            key: user.id,
                            title: `${user.firstName} ${user.lastName} / ${user.email}`
                        }))}
                    />
                    <Select
                        adjustWidth
                        icon="cog"
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.ticket")}
                        value={type}
                        onChange={type => {
                            this.props.onChangeType(type);
                        }}
                        items={types.map(type => ({
                            key: type.id, title: type.name
                        }))}
                    />
                    <Select
                        adjustWidth
                        icon="mailbox"
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.source")}
                        value={channelType}
                        onChange={channelType => {
                            this.props.onChangeChannelType(channelType);
                        }}
                        items={[
                            { key: "manual", title: i18next.t("page.private.ticket-system.tickets.searchPlaceholder.sourceTypes.manual") },
                            { key: "servicedesk", title: i18next.t("page.private.ticket-system.tickets.searchPlaceholder.sourceTypes.servicedesk") },
                            { key: "buildingInspection", title: i18next.t("page.private.ticket-system.tickets.searchPlaceholder.sourceTypes.buildingInspection") }
                        ]}
                    />
                    <Select
                        adjustWidth
                        icon="mailbox"
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.buildingInspectionTemplate")}
                        value={buildingInspectionTemplateId}
                        onChange={buildingInspectionTemplateId => {
                            this.props.onChangeBuildingInspectionTemplateId(buildingInspectionTemplateId);
                        }}
                        items={this.props.buildingInspectionTemplates.map(bit => ({
                            key: bit.id,
                            title: bit.name
                        }))}
                    />
                    <Select
                        adjustWidth
                        icon="person-walking"
                        label={i18next.t("page.private.ticket-system.tickets.searchPlaceholder.buildingInspectionType")}
                        value={buildingInspectionType}
                        onChange={buildingInspectionType => {
                            this.props.onChangeBuildingInspectionType(buildingInspectionType);
                        }}
                        items={[
                            { key: "safetyInspection", title: "Sicherheitsbegehung", icon: "hard-hat" },
                            { key: "defectView", title: "Mängelansicht", icon: "bolt" },
                            { key: "maintenance", title: "Wartung", icon: "clipboard-list" },
                            { key: "test", title: "Probelauf", icon: "message-smile" },
                            { key: "visualControl", title: "Sichtkontrolle", icon: "eye" },
                            { key: "riskAssessment", title: "Gefährdungsbeurteilung", icon: "brake-warning" },
                            { key: "asa", title: "ASA-Begehung", icon: "user-doctor" },
                            { key: "sibe", title: "SiBe-Begehung", icon: "shield-check" }
                        ]}
                    />
                </div>
            </GridElement>
        </StyledMenuBar>
    }
}

export default withRouter(class extends Component {
    state = { archived: [], tickets: [], subsidiaries: [], users: [], buildingInspectionTemplates: [], ticketEscalationSteps: [], types: [], subsidiaryId: null, ticketEscalationStepId: null, priority: null, userId: null, typeId: null, channelType: null }

    constructor(props) {
        super(props)

        const savedTicketEscalationStepId = Cookies.get('ticketEscalationStepId');
        const savedSubsidiaryId = Cookies.get('subsidiaryId');
        const savedPriority = Cookies.get('priority');
        const savedUserId = Cookies.get('userId');
        const savedType = Cookies.get('typeId');
        const savedChannelType = Cookies.get('channelType');
        const savedBuildingInspectionType = Cookies.get('buildingInspectionType');
        const savedBuildingInspectionTemplateId = Cookies.get('buildingInspectionTemplateId');

        this.state.ticketEscalationStepId = savedTicketEscalationStepId === "null" ? null : (savedTicketEscalationStepId || null),
        this.state.subsidiaryId = savedSubsidiaryId === "null" ? null : (savedSubsidiaryId || null),
        this.state.priority = savedPriority === "null" ? null : (savedPriority || null),
        this.state.userId = savedUserId === "null" ? null : (savedUserId || null),
        this.state.typeId = savedType === "null" ? null : (savedType || null),
        this.state.channelType = savedChannelType === "null" ? null : (savedChannelType || null)
        this.state.buildingInspectionType = savedBuildingInspectionType === "null" ? null : (savedBuildingInspectionType || null)
        this.state.buildingInspectionTemplateId = savedBuildingInspectionTemplateId === "null" ? null : (savedBuildingInspectionTemplateId || null)

        this.fetch = this.fetch.bind(this)
        this.fetch(true)
    }

    async fetch(initialLoad = false) {
        let queries = []

        if(this.state.ticketEscalationStepId) {
            queries.push({ ticketEscalationStepId: this.state.ticketEscalationStepId })
        }

        if(this.state.subsidiaryId) {
            queries.push({ subsidiaryId: this.state.subsidiaryId })
        }

        if(this.state.priority) {
            queries.push({ priority: this.state.priority })
        }

        if(this.state.userId) {
            queries.push({ userId: this.state.userId })
        }

        if(this.state.typeId) {
            queries.push({ typeId: this.state.typeId })
        }

        const [ ticketData, data ] = await Promise.all([
            fetch(scanmetrix.backendURL + "/tickets?" + queries.map(q => `${Object.keys(q)[0]}=${q[Object.keys(q)[0]]}`).join("&"), {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(result => result.json()),
            scanmetrix.client.query({
                query: scanmetrix.gql`
                    query {
                        TicketTypes {
                            nodes {
                                id
                                name
                                color
                                icon
                                active
                            }
                        }
                        Subsidiaries {
                            nodes {
                                id
                                name
                                label
                                address {
                                    inline
                                }
                            }
                        }
                        TicketEscalationSteps {
                            nodes {
                                id
                                description
                                color
                            }
                        }
                        Users {
                            nodes {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                        BuildingInspectionTemplates {
                            nodes {
                                id
                                name
                            }
                        }
                    }
                `
            })
        ])

        this.setState({
            tickets: ticketData.tickets.filter(ticket => {
                if(ticket.archived) return false
                if(!this.state.channelType) return true

                return ticket.channel.type === this.state.channelType
            }).filter(ticket => {
                if(!this.state.buildingInspectionTemplateId) return true

                return ticket.buildingInspection && ticket.buildingInspection.buildingInspectionTemplate && String(ticket.buildingInspection.buildingInspectionTemplate.id) === String(this.state.buildingInspectionTemplateId)
            }).filter(ticket => {
                if(!this.state.buildingInspectionType) return true

                return ticket.buildingInspection && ticket.buildingInspection.buildingInspectionTemplate && ticket.buildingInspection.buildingInspectionTemplate.type === this.state.buildingInspectionType
            }),
            archived: ticketData.tickets.filter(ticket => ticket.archived),
            subsidiaries: data.data.Subsidiaries.nodes,
            ticketEscalationSteps: data.data.TicketEscalationSteps.nodes,
            buildingInspectionTemplates: data.data.BuildingInspectionTemplates.nodes,
            users: data.data.Users.nodes,
            types: data.data.TicketTypes.nodes
        }, () => {
            if(initialLoad === true) {
                const openId = new URLSearchParams(window.location.search).get("open")

                if(openId) this.updateTicketModal.open(openId)
            }
        })
    }

    render() {
        const tickets = this.state.tickets

        const unrepliedFilter = ticket => {
            if(ticket.chats.length === 0) return false

            if(ticket.chats[0].fromEmail) return true

            return (ticket.chats[0].serviceDeskUser || ticket.chats[0].user).email.toLowerCase() === ticket.channel.user.email.toLowerCase()
        }

        const cards = tickets.map(ticket => ({
            id: ticket.id,
            status: ticket.status,
            title: <div style={{ display: "flex", alignItems: "center", height: 24 }}>{ticket.user && <AvatarHolder>
                <Avatar className="avatar" size="24" email={scanmetrix.disableGravatar ? null : ticket.user.email} name={`${ticket.user.firstName} ${ticket.user.lastName}`} />
            </AvatarHolder>}<p>{`Ticket ${ticket.number}`}</p></div>,
            description: <div>
                {ticket.title}
                <br />
                <p style={{ fontSize: "12px" }}><b>{ticket.subsidiary.name}</b>: {ticket.subsidiary.address.inline}</p>
                {ticket.ticketEscalationStep && <p style={{ fontSize: "12px" }}><b>Eskalationsstufe</b>: <i className="far fa-message-exclamation" style={{ color: ticket.ticketEscalationStep.color }} /> {ticket.ticketEscalationStep.description}</p>}
                {ticket.buildingInspection && <>
                    <p style={{ color: "#16a085", fontSize: "12px" }}><b>Begehung</b>: {ticket.buildingInspection.buildingInspectionTemplate.name} vom {moment(ticket.buildingInspection.createdAt).format("DD.MM.YYYY")}</p>
                </>}
            </div>,
            label: <div style={{ display: "flex", alignItems: "center" }}>
                {moment(ticket.updatedAt).fromNow()}
                <p style={{ marginLeft: 8, color: ticket.comments.length > 0 ? "#3b97d3" : "rgba(0, 0, 0, 0.5)", fontWeight: 600}}><i className="fa-duotone fa-comments" /> {ticket.comments.length}</p>
            </div>,
            index: ticket.index,
            tags: [
                getChannelAsTag(ticket.channel),
                getTypeAsTag(ticket.type.id, this.state.types),
                getPriorityAsTag(ticket.priority),
            ]
        })).sort((a, b) => a.index - b.index)

        const data = {
            lanes: [
                {
                    id: 'unassigned',
                    title: <><i className="fa-duotone fa-question-circle" style={{ marginRight: 4, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.tickets.status.unassigned")}</>,
                    label: cards.filter(card => card.status === "unassigned").length + " (oberste 100)",
                    cards: [...cards.filter(card => card.status === "unassigned")].slice(0, 100)
                },
                {
                    id: 'assigned',
                    title: <><i className="fa-duotone fa-user" style={{ marginRight: 4, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.tickets.status.assigned")}</>,
                    label: cards.filter(card => card.status === "assigned").length + " (oberste 100)",
                    cards: [...cards.filter(card => card.status === "assigned")].slice(0, 100),

                },
                {
                    id: 'active',
                    title: <><i className="fa-duotone fa-wrench" style={{ marginRight: 4, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.tickets.status.inProcess")}</>,
                    label: cards.filter(card => card.status === "active").length + " (oberste 100)",
                    cards: [...cards.filter(card => card.status === "active")].slice(0, 100)
                },
                {
                    id: 'done',
                    title: <><i className="fa-duotone fa-check" style={{ marginRight: 4, color: "#3b97d3" }} /> {i18next.t("page.private.ticket-system.tickets.status.completed")}{/*} in den letzten 7 Tagen*/}</>,
                    label: cards.filter(card => card.status === "done").length + " (oberste 100)",
                    cards: [...cards.filter(card => card.status === "done")].slice(0, 100)
                }
            ]
        }

        return <Page {...this.props}>
            <CreateTicketModal types={this.state.types} users={this.state.users} subsidiaries={this.state.subsidiaries} refresh={() => this.fetch()} instance={ref => this.createTicketModal = ref} />
            <UpdateTicketModal refresh={() => this.fetch()} instance={ref => this.updateTicketModal = ref} />
            <AssignTicketModal users={this.state.users} refresh={() => this.fetch()} instance={ref => this.assignTicketModel = ref} />
            <DeleteTicketModal refresh={() => this.fetch()} instance={ref => this.deleteTicketModal = ref} />
            <Breadcrumbs values={[
                {
                    icon: "comments-alt",
                    title: i18next.t("page.private.ticket-system.breadCrumbsTitle")
                }
            ]} />
            <MenuBar
                types={this.state.types}
                onCreate={() => this.createTicketModal.open()}
                subsidiaries={this.state.subsidiaries}
                ticketEscalationSteps={this.state.ticketEscalationSteps}
                users={this.state.users}
                subsidiaryId={this.state.subsidiaryId}
                ticketEscalationStepId={this.state.ticketEscalationStepId}
                buildingInspectionTemplates={this.state.buildingInspectionTemplates}
                onChangeTicketEscalationStep={ticketEscalationStepId => {
                    Cookies.set('ticketEscalationStepId', ticketEscalationStepId, { expires: 90 });
                    this.setState({ ticketEscalationStepId }, this.fetch);
                }}
                onChangeSubsidiary={subsidiaryId => {
                    Cookies.set('subsidiaryId', subsidiaryId, { expires: 90 });
                    this.setState({ subsidiaryId }, this.fetch);
                }}
                priority={this.state.priority}
                onChangePriority={priority => {
                    Cookies.set('priority', priority, { expires: 90 });
                    this.setState({ priority }, this.fetch);
                }}
                userId={this.state.userId}
                onChangeUserId={userId => {
                    Cookies.set('userId', userId, { expires: 90 });
                    this.setState({ userId }, this.fetch);
                }}
                type={this.state.typeId}
                onChangeType={typeId => {
                    Cookies.set('typeId', typeId, { expires: 90 });
                    this.setState({ typeId }, this.fetch);
                }}
                channelType={this.state.channelType}
                onChangeChannelType={channelType => {
                    Cookies.set('channelType', channelType, { expires: 90 });
                    this.setState({ channelType }, this.fetch);
                }}
                buildingInspectionType={this.state.buildingInspectionType}
                onChangeBuildingInspectionType={buildingInspectionType => {
                    Cookies.set('buildingInspectionType', buildingInspectionType, { expires: 90 });
                    this.setState({ buildingInspectionType }, this.fetch);
                }}
                buildingInspectionTemplateId={this.state.buildingInspectionTemplateId}
                onChangeBuildingInspectionTemplateId={buildingInspectionTemplateId => {
                    Cookies.set('buildingInspectionTemplateId', buildingInspectionTemplateId, { expires: 90 });
                    this.setState({ buildingInspectionTemplateId }, this.fetch);
                }}
            />
            <Section bodyPadding="24px 0 0 0">
                <Section.Item title={i18next.t("page.private.ticket-system.tickets.title")} icon="square-kanban" amount={this.state.tickets.length}>
                    <Board
                        tagStyle={{ padding: "6px 10px", fontSize: "12px", margin: "2px 0 0 4px" }}
                        data={data}
                        hideCardDeleteIcon
                        cardDraggable={scanmetrix.checkPermission("Ticket") > 2}
                        laneStyle={{ transition: "all 0.3s", width: "25%", boxShadow: "0 8px 24px -16px rgb(0 0 0 / 40%)", background: "white", padding: "14px 10px 3px 10px" }}
                        cardStyle={{ border: "1px solid rgba(0, 0, 0, 0.1)", width: "100%", maxWidth: "100%" }}
                        style={{ height: "800px", background: "transparent", padding: 0, marginLeft: -5, width: "calc(100% + 10px)" }}
                        onCardClick={ticketId => this.updateTicketModal.open(ticketId)}
                        handleDragEnd={(cardId, sourceLaneId, targetLaneId, position) => {
                            if (sourceLaneId === "unassigned" && targetLaneId === "assigned") {
                                this.assignTicketModel.open({id: cardId, index: position, status: targetLaneId})
                                return false
                            } else if(sourceLaneId === "unassigned" && (targetLaneId === "active" || targetLaneId === "done")) {
                                scanmetrix.client.mutate({
                                    mutation: scanmetrix.gql`
                                        mutation($id: ID!, $userId: ID!, $index: Int!, $status: String!) {
                                            assignTicket(id: $id, userId: $userId, index: $index, status: $status)
                                        }
                                    `,
                                    variables: {
                                        id: cardId,
                                        userId: scanmetrix.session.id,
                                        status: targetLaneId,
                                        index: position
                                    }
                                }).then(this.fetch)
                            } else {
                                if(targetLaneId === sourceLaneId) {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($ticketId: ID!, $index: Int!) {
                                                updateTicketPosition(id: $ticketId, index: $index)
                                            }
                                        `,
                                        variables: {
                                            ticketId: cardId,
                                            index: position
                                        }
                                    }).then(this.fetch)

                                    return true
                                } else {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($ticketId: ID!, $index: Int!, $status: String!) {
                                                updateTicketStatus(id: $ticketId, index: $index, status: $status)
                                            }
                                        `,
                                        variables: {
                                            ticketId: cardId,
                                            index: position,
                                            status: targetLaneId
                                        }
                                    }).then(this.fetch)

                                    return true
                                }
                            }
                        }}
                    />
                </Section.Item>
                <Section.Item title={i18next.t("page.private.ticket-system.tickets.titleTwo")} icon="table" amount={this.state.tickets.length}>
                <GridElement styled title={i18next.t("page.private.ticket-system.tickets.titleTwo")} icon="box-archive">
                    <Table
                        head={[
                            { title: i18next.t("page.private.ticket-system.archive.table.createdBy"), width: "10%", column: "createdBy" },
                            { title: i18next.t("page.private.ticket-system.archive.table.number"), width: "7.5%", column: "number" },
                            { title: i18next.t("page.private.ticket-system.archive.table.title"), width: "12.5%", column: "title" },
                            { title: i18next.t("page.private.ticket-system.archive.table.assigned"), width: "7.5%", column: "assigned" },
                            { title: i18next.t("page.private.ticket-system.archive.table.branch"), width: "20%", column: "subsidiary" },
                            { title: i18next.t("page.private.ticket-system.archive.table.source"), width: "10%", column: "channel" },
                            { title: i18next.t("page.private.ticket-system.archive.table.type"), width: "10%", column: "type" },
                            { title: i18next.t("page.private.ticket-system.archive.table.priority"), width: "12.5%", column: "priority" },
                            { title: i18next.t("page.private.ticket-system.archive.table.lastEdited"), width: "10%", column: "updatedAt" }
                        ]}
                        onItemClick={ticket => this.updateTicketModal.open(ticket.id)}
                        items={this.state.tickets ? this.state.tickets.sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()).map(ticket => ({
                            id: ticket.id,
                            number: ticket.number,
                            title: ticket.title,
                            createdBy: <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                    <Avatar size="32" email={scanmetrix.disableGravatar ? null : ticket.channel.user.email} name={`${ticket.channel.user.firstName} ${ticket.channel.user.lastName}`} className="avatarInner" />
                                </div>
                                {ticket.channel.user.firstName} {ticket.channel.user.lastName}
                            </div>,
                            assigned: ticket.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                    <Avatar size="32" email={scanmetrix.disableGravatar ? null : ticket.user.email} name={`${ticket.user.firstName} ${ticket.user.lastName}`} className="avatarInner" />
                                </div>
                                {ticket.user.firstName} {ticket.user.lastName}
                            </div> : null,
                            subsidiary: ticket.subsidiary ? `${ticket.subsidiary.name}${ticket.subsidiary.label ? (" (" + ticket.subsidiary.label + ")") : ""} / ${ticket.subsidiary.address.postalCode} ${ticket.subsidiary.address.city}` : null,
                            updatedAt: moment(ticket.updatedAt).fromNow(),
                            priority: <div style={{ color: "white", background: getPriorityAsTag(ticket.priority).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getPriorityAsTag(ticket.priority).title}</div>,
                            type: <div style={{ color: "white", background: getTypeAsTag(ticket.type.id, this.state.types).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getTypeAsTag(ticket.type.id, this.state.types).title}</div>,
                            channel: <div style={{ color: "white", background: getChannelAsTag(ticket.channel).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getChannelAsTag(ticket.channel).title}</div>
                        })) : null}
                    />
                </GridElement>
            </Section.Item>
                <Section.Item title={i18next.t("page.private.ticket-system.tickets.unreplied")} icon="message-smile" amount={this.state.tickets.filter(unrepliedFilter).length} amountColor={this.state.tickets.filter(unrepliedFilter).length > 0 ? "#e67e22" : "#16a085"}>
                    <GridElement styled title={i18next.t("page.private.ticket-system.tickets.unreplied")} icon="message-smile">
                        <Table
                            head={[
                                { title: i18next.t("page.private.ticket-system.archive.table.createdBy"), width: "10%", column: "createdBy" },
                                { title: i18next.t("page.private.ticket-system.archive.table.number"), width: "7.5%", column: "number" },
                                { title: i18next.t("page.private.ticket-system.archive.table.title"), width: "12.5%", column: "title" },
                                { title: i18next.t("page.private.ticket-system.archive.table.assigned"), width: "7.5%", column: "assigned" },
                                { title: i18next.t("page.private.ticket-system.archive.table.branch"), width: "20%", column: "subsidiary" },
                                { title: i18next.t("page.private.ticket-system.archive.table.source"), width: "10%", column: "channel" },
                                { title: i18next.t("page.private.ticket-system.archive.table.type"), width: "10%", column: "type" },
                                { title: i18next.t("page.private.ticket-system.archive.table.priority"), width: "12.5%", column: "priority" },
                                { title: i18next.t("page.private.ticket-system.archive.table.lastEdited"), width: "10%", column: "updatedAt" }
                            ]}
                            onItemClick={ticket => this.updateTicketModal.open(ticket.id)}
                            items={this.state.tickets ? this.state.tickets.filter(unrepliedFilter).sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()).map(ticket => ({
                                id: ticket.id,
                                number: ticket.number,
                                title: ticket.title,
                                createdBy: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : ticket.channel.user.email} name={`${ticket.channel.user.firstName} ${ticket.channel.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {ticket.channel.user.firstName} {ticket.channel.user.lastName}
                                </div>,
                                assigned: ticket.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : ticket.user.email} name={`${ticket.user.firstName} ${ticket.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {ticket.user.firstName} {ticket.user.lastName}
                                </div> : null,
                                subsidiary: ticket.subsidiary ? `${ticket.subsidiary.name}${ticket.subsidiary.label ? (" (" + ticket.subsidiary.label + ")") : ""} / ${ticket.subsidiary.address.postalCode} ${ticket.subsidiary.address.city}` : null,
                                updatedAt: moment(ticket.updatedAt).fromNow(),
                                priority: <div style={{ color: "white", background: getPriorityAsTag(ticket.priority).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getPriorityAsTag(ticket.priority).title}</div>,
                                type: <div style={{ color: "white", background: getTypeAsTag(ticket.type.id, this.state.types).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getTypeAsTag(ticket.type.id, this.state.types).title}</div>,
                                channel: <div style={{ color: "white", background: getChannelAsTag(ticket.channel).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getChannelAsTag(ticket.channel).title}</div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.ticket-system.archive.title")} icon="box-archive" amount={this.state.archived.length}>
                    <GridElement styled title={i18next.t("page.private.ticket-system.archive.gridElementTitle")} icon="box-archive">
                        <Table
                            head={[
                                { title: i18next.t("page.private.ticket-system.archive.table.createdBy"), width: "10%", column: "createdBy" },
                                { title: i18next.t("page.private.ticket-system.archive.table.number"), width: "7.5%", column: "number" },
                                { title: i18next.t("page.private.ticket-system.archive.table.title"), width: "20%", column: "title" },
                                { title: i18next.t("page.private.ticket-system.archive.table.branch"), width: "20%", column: "subsidiary" },
                                { title: i18next.t("page.private.ticket-system.archive.table.source"), width: "12.5%", column: "channel" },
                                { title: i18next.t("page.private.ticket-system.archive.table.type"), width: "10%", column: "type" },
                                { title: i18next.t("page.private.ticket-system.archive.table.priority"), width: "10%", column: "priority" },
                                { title: i18next.t("page.private.ticket-system.archive.table.lastEdited"), width: "10%", column: "updatedAt" }
                            ]}
                            contextItems={scanmetrix.checkPermission("Ticket") >= 4 ? [
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.ticket-system.archive.deleteButton"),
                                    onClick: data => {
                                        this.deleteTicketModal.open(data.id)
                                    }
                                }
                            ] : null}
                            onItemClick={ticket => this.updateTicketModal.open(ticket.id)}
                            items={this.state.archived ? this.state.archived.sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()).map(ticket => ({
                                id: ticket.id,
                                number: ticket.number,
                                createdBy: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : ticket.channel.user.email} name={`${ticket.channel.user.firstName} ${ticket.channel.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {ticket.channel.user.firstName} {ticket.channel.user.lastName}
                                </div>,
                                title: ticket.title,
                                subsidiary: ticket.subsidiary ? `${ticket.subsidiary.name}${ticket.subsidiary.label ? (" (" + ticket.subsidiary.label + ")") : ""} / ${ticket.subsidiary.address.postalCode} ${ticket.subsidiary.address.city}` : null,
                                updatedAt: moment(ticket.updatedAt).fromNow(),
                                priority: <div style={{ color: "white", background: getPriorityAsTag(ticket.priority).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getPriorityAsTag(ticket.priority).title}</div>,
                                type: <div style={{ color: "white", background: getTypeAsTag(ticket.type.id, this.state.types).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getTypeAsTag(ticket.type.id, this.state.types).title}</div>,
                                channel: <div style={{ color: "white", background: getChannelAsTag(ticket.channel).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getChannelAsTag(ticket.channel).title}</div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </Page>
    }
})

const AvatarHolder = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 8px;
    overflow: hidden;

    .sb-avatar, .avatar {
        vertical-align: top !important;
    }
`

const getPriorityAsTag = priority => {
    switch(priority) {
        case "low":
            return { title: i18next.t("page.private.ticket-system.tickets.switchCases.lowPriority"), color: "white", bgcolor: "#1abc9c" }
        case "medium":
            return { title: i18next.t("page.private.ticket-system.tickets.switchCases.mediumPriority"), color: "white", bgcolor: "#e67e22" }
        case "high":
            return { title: i18next.t("page.private.ticket-system.tickets.switchCases.highPriority"), color: "white", bgcolor: "#e74c3c" }
    }
}
const getStatusAsTag = status => {
    switch(status) {
        case "unassigned":
            return { title: i18next.t("page.private.ticket-system.tickets.status.unassigned"), color: "white", bgcolor: "#1abc9c"}
        case "assigned":
            return { title: i18next.t("page.private.ticket-system.tickets.status.assigned"), color: "white", bgcolor: "#e67e22"}
        case "active":
            return { title: i18next.t("page.private.ticket-system.tickets.status.active"), color: "white", bgcolor: "#e74c3c" }
        case "done":
            return { title: i18next.t("page.private.ticket-system.tickets.status.done"), color: "white", bgcolor: "#e74c3c"}
    }

}

const getTypeAsTag = (typeId, types) => {
    const foundType = types.find(t => String(t.id) === String(typeId)) || {}

    return { title: foundType.name, color: "white", bgcolor: foundType.color, icon: foundType.icon }
}

const getChannelAsTag = channel => {
    if(!channel) return { title: i18next.t("page.private.ticket-system.tickets.switchCases.unknown"), color: 'white', bgcolor: '#95a5a6' }

    if(channel.type === "manual") {
        return { title: i18next.t("page.private.ticket-system.tickets.switchCases.userDefined"), color: 'white', bgcolor: '#34495e' }
    } else if (channel.type === "servicedesk") {
        return { title: i18next.t("page.private.ticket-system.tickets.switchCases.serviceDesk"), color: 'white', bgcolor: '#3b97d3' }
    } else if (channel.type === "buildingInspection") {
        return { title: i18next.t("page.private.ticket-system.tickets.switchCases.inspection"), color: 'white', bgcolor: '#16a085' }
    }

    return { title: i18next.t("page.private.ticket-system.tickets.switchCases.unknown"), color: 'white', bgcolor: '#95a5a6' }
}

export { getTypeAsTag, getPriorityAsTag, getChannelAsTag, getStatusAsTag }
