import React, { Component } from "react"
import i18next from "i18next";
import { Page, Breadcrumbs, Section, GridElement, Select, Input, Grid, Table, Button, Checkbox, Modal } from "scanmetrix-components"
import {getChannelAsTag, getPriorityAsTag, getStatusAsTag, getTypeAsTag} from "../tickets";
import moment from "moment"
import {FileManager} from "../documents";
import UpdateTicketModal from "../tickets/modals/UpdateTicketModal";
import CreateMeasureOfferRequestModal from "./modals/CreateMeasureOfferRequestModal";
import Avatar from "react-avatar";
import ShowMeasureOfferRequestModal from "./modals/ShowMeasureOfferRequestModal";
import UploadOfferModal from "./modals/UploadOfferModal";
import CancelMeasureOfferRequestModal from "./modals/CancelMeasureOfferRequestModal";
import RemindMeasureOfferRequestModal from "./modals/RemindMeasureOfferRequestModal";
import prettyBytes from "pretty-bytes";
import CreateMeasureApprovalModal from "./modals/CreateMeasureApprovalModal";
import { getType as getPOType, getStatus as getPOStatus } from "../workOrders";
import DeleteMeasureOfferModal from "./modals/DeleteMeasureOfferModal";
import DeleteMeasureApprovalModal from "./modals/DeleteMeasureApprovalModal";
import ApproveMeasureApprovalModal from "./modals/ApproveMeasureApprovalModal";
import ShowMeasureApprovalModal from "./modals/ShowMeasureApprovalModal";
import CancelMeasureApprovalModal from "./modals/CancelMeasureApprovalModal";
import UpdateMeasureOfferStatusModal from "./modals/UpdateMeasureOfferStatusModal";

import styled from "styled-components";
import CreatePurchaseOrderModal from "./modals/CreatePurchaseOrderModal";
import CreateManualPurchaseOrderModal from "./modals/CreateManualPurchaseOrderModal";
import {UpdateAppointmentModal} from "../calendar";

const getStatus = status => {
    switch(status) {
        case "unedited": return ({ title: i18next.t("page.private.measure.offerStatus.unedited"), icon: "pencil", color: "#95a5a6" })
        case "approval": return ({ title: i18next.t("page.private.measure.offerStatus.approval"), icon: "check-to-slot", color: "#3b97d3" })
        case "accepted": return ({ title: i18next.t("page.private.measure.offerStatus.accepted"), icon: "check", color: "#1abc9c" })
        case "declined": return ({ title: i18next.t("page.private.measure.offerStatus.declined"), icon: "times", color: "#e74c3c" })
        case "purchased": return ({ title: i18next.t("page.private.measure.offerStatus.purchased"), icon: "comment-alt-check", color: "#1abc9c" })
    }
}

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})


export default class extends Component {
    render() {
        return <Page {...this.props}>
            <MeasurePage {...this.props} />
        </Page>
    }
}

class MeasurePage extends Component {
    state = { measure: null, letterHeads: [], users: [], types: [], appointments: null, tickets: null, loading: true }

    constructor(props) {
        super(props);

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        this.setState({ loading: true })

        return scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    TicketTypes {
                        nodes {
                            id
                            name
                            color
                            icon
                            active
                        }
                    }
                    Measure(id: $id) {
                        id
                        title
                        number
                        type
                        status
                        begin
                        end
                        offers {
                            id
                            number
                            createdAt
                            status
                            date
                            net
                            gross
                            size
                            name
                            serviceProvider {
                                id
                                name
                            }
                            user {
                                id
                                email
                                firstName
                                lastName
                            }
                        }
                        letterHead {
                            id
                            name
                        }
                        user {
                            id
                        }
                        appointments {
                            id
                            allDay
                            duration
                            date
                            name
                            description
                            type
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                        subsidiary {
                            id
                            name
                            label
                            legalName
                            address {
                                streetName
                                streetNumber
                                country
                                additional
                                postalCode
                                city
                            }
                        }
                    }
                   
                    LetterHeads {
                        nodes {
                            id
                            name
                        }
                    }
                    Users {
                        nodes {
                            id
                            firstName
                            lastName
                            email
                        }
                    }
                    MeasureApprovals(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            status
                            createdAt
                            email
                            fee
                            name
                            text
                            cancelTitle
                            cancelledAt
                            cancelEmail
                            cancelText
                            cancelledBy {
                                id
                                firstName
                                lastName
                                email
                            }
                            title
                            approvedAt
                            measureOffers {
                                id
                                net
                            } 
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                    PurchaseOrders(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            title
                            number
                            status
                            date
                            type
                            positions
                            letterHead {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                                locationLat
                                locationLng
                                address {
                                    postalCode
                                    city
                                }
                            }
                            accountingInvoices {
                                id
                                number
                                date
                                net
                                gross
                                notes
                                status
                                declined
                                accountingInvoiceDocument {
                                    id
                                    name
                                }
                                serviceProvider {
                                    id
                                    name
                                }
                                subsidiary {
                                    id
                                    name
                                    label
                                }
                            }
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                    MeasureOfferRequests(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            cancelTitle
                            cancelledAt
                            cancelEmail
                            remindedAt
                            cancelText
                            cancelledBy {
                                id
                                firstName
                                lastName
                                email
                            }
                            email
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                            receivedAt
                            text
                            title
                            serviceProvider {
                                id
                                name
                            }
                            measureOffer {
                                id
                                number
                                createdAt
                                status
                                date
                                net
                                gross
                                size
                                name
                            }
                            createdAt
                        }
                    }
                    Tickets(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            number
                            type {
                                id
                            }
                            status
                            title
                            priority
                            smid
                            index
                            archived
                            comments {
                                id
                            }
                            user {
                                id
                                email
                                firstName
                                lastName
                            }
                            subsidiary {
                                id
                                name
                                legalName
                                label
                                address {
                                    streetName
                                    streetNumber
                                    country
                                    additional
                                    postalCode
                                    city
                                }
                            }
                            channel
                            description
                            updatedAt
                            createdAt
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.measureid
            }
        }).then(data => {
            this.setState({
                users: data.data.Users.nodes,
                measure: data.data.Measure,
                letterHeads: data.data.LetterHeads.nodes,
                tickets: data.data.Tickets.nodes,
                approvals: data.data.MeasureApprovals.nodes,
                purchaseOrders: data.data.PurchaseOrders.nodes,
                offerRequests: data.data.MeasureOfferRequests.nodes,
                offers: [ ...data.data.MeasureOfferRequests.nodes.map(n => n.measureOffer ? ({ ...n.measureOffer, request: n }) : null).filter(n => !!n), ...data.data.Measure.offers ].filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i),
                accountingInvoices: data.data.PurchaseOrders.nodes.map(n => n.accountingInvoices.map(invoice => ({ ...invoice, purchaseOrder: n }))).flat(),
                appointments: data.data.Measure.appointments,
                loading: false,
                types: data.data.TicketTypes.nodes
            })
        })
    }

    render() {
        const measure = this.state.measure

        return <>
            <Breadcrumbs values={[
                {
                    icon: "network-wired",
                    title: i18next.t("page.private.measure.breadCrumbsTitle"),
                    link: "/measures"
                },
                ...(measure ? [{
                    title: measure.number
                }] : [])
            ]} />
            <UpdateTicketModal refresh={() => this.fetch()} instance={ref => this.updateTicketModal = ref} />
            {measure && <CancelMeasureOfferRequestModal measure={measure} refresh={() => this.fetch()} instance={ref => this.cancelOfferRequestModal = ref} />}
            {measure && <CancelMeasureApprovalModal measure={measure} refresh={() => this.fetch()} instance={ref => this.cancelMeasureApprovalModal = ref} />}
            {measure && <RemindMeasureOfferRequestModal measure={measure} refresh={() => this.fetch()} instance={ref => this.remindOfferRequestModal = ref} />}
            {measure && <CreatePurchaseOrderModal measure={measure} refresh={() => this.fetch()} instance={ref => this.createPurchaseOrderModal = ref} />}
            {measure && <CreateManualPurchaseOrderModal subsidiary={measure.subsidiary} measure={measure} refresh={() => this.fetch()} instance={ref => this.createManualPurchaseOrderModal = ref} />}
            <ShowMeasureOfferRequestModal instance={ref => this.showMeasureOfferRequestModal = ref} />
            {measure && <CreateAppointmentModal refresh={() => this.fetch()} subsidiaryId={measure.subsidiary.id} measureId={measure.id} instance={ref => this.createAppointmentModal = ref} />}
            <ShowMeasureApprovalModal instance={ref => this.showMeasureApprovalModal = ref} />
            <UploadOfferModal measureId={measure ? measure.id : null} refresh={() => this.fetch()} instance={ref => this.uploadOfferModal = ref} />
            <UpdateAppointmentModal refresh={() => this.fetch()} instance={ref => this.updateAppointmentModal = ref} />
            <UpdateMeasureOfferStatusModal refresh={() => this.fetch()} instance={ref => this.updateMeasureOfferStatusModal = ref} />
            <ApproveMeasureApprovalModal refresh={() => this.fetch()} instance={ref => this.approveMeasureApprovalModal = ref} />
            <DeleteMeasureOfferModal refresh={() => this.fetch()} instance={ref => this.deleteMeasureOfferModal = ref}  />
            <DeleteMeasureApprovalModal refresh={() => this.fetch()} instance={ref => this.deleteMeasureApprovalModal = ref} />
            {measure && <CreateMeasureOfferRequestModal measure={measure} refresh={() => this.fetch()} instance={ref => this.createOfferRequestModal = ref} />}
            {measure && <CreateMeasureApprovalModal offers={this.state.offers} measure={measure} refresh={() => this.fetch()} instance={ref => this.createApprovalModal = ref} />}
            {measure && <Input.MutationProvider value={{
                name: "updateMeasure",
                id: measure.id,
                data: measure
            }}>
                <div style={{ marginBottom: 32 }}>
                    <GridElement styled title={i18next.t("page.private.measure.summary.gridElementTitle")} icon="cog">
                        <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr", "1fr" ]}>
                            <GridElement columnStart={1} columnEnd={4}>
                                <Input readOnly={scanmetrix.checkPermission("Measure") < 3} adjustWidth label={i18next.t("page.private.measure.summary.title")} icon="font" field="title"  />
                            </GridElement>
                            <GridElement columnStart={4} columnEnd={5}>
                                <Input readOnly={scanmetrix.checkPermission("Measure") < 3} adjustWidth label={i18next.t("page.private.measure.summary.start")} icon="hourglass-start" field="begin" date />
                            </GridElement>
                            <GridElement columnStart={5} columnEnd={6}>
                                <Input readOnly={scanmetrix.checkPermission("Measure") < 3} adjustWidth label={i18next.t("page.private.measure.summary.end")} icon="hourglass-end" field="end" date />
                            </GridElement>
                            <GridElement columnStart={1} columnEnd={2}>
                                <Select readOnly={scanmetrix.checkPermission("Measure") < 3} field="user.id" label={i18next.t("page.private.measure.summary.clerk")} noUnselect icon="user" adjustWidth items={this.state.users.map(user => ({ key: user.id, title: `${user.firstName} ${user.lastName} / ${user.email}` }))} />
                            </GridElement>
                            <GridElement columnStart={2} columnEnd={3}>
                                <Input readOnly={scanmetrix.checkPermission("Measure") < 3} adjustWidth label={i18next.t("page.private.measure.summary.number")} icon="tally" field="number" />
                            </GridElement>
                            <GridElement columnStart={3} columnEnd={4}>
                                <Select disabled={scanmetrix.checkPermission("Measure") < 3} field="status" noUnselect icon="pencil" label={i18next.t("page.private.measure.summary.status")} adjustWidth items={[
                                    { key: "draft", title: i18next.t("page.private.measure.summary.statusTypes.draft") },
                                    { key: "ongoing", title: i18next.t("page.private.measure.summary.statusTypes.ongoing") },
                                    { key: "cancelled", title: i18next.t("page.private.measure.summary.statusTypes.canceled") },
                                    { key: "done", title: i18next.t("page.private.measure.summary.statusTypes.completed") }
                                ]} />
                            </GridElement>
                            <GridElement columnStart={4} columnEnd={5}>
                                <Select disabled={scanmetrix.checkPermission("Measure") < 3} field="letterHead.id" noUnselect icon="scroll" label={i18next.t("page.private.measure.summary.letterHead")} adjustWidth items={this.state.letterHeads.map(letterHead => ({
                                    key: letterHead.id,
                                    title: letterHead.name
                                }))} />
                            </GridElement>
                            <GridElement columnStart={5} columnEnd={6}>
                                <Select disabled={scanmetrix.checkPermission("Measure") < 3} field="type" noUnselect icon="cog" label={i18next.t("page.private.measure.summary.type")} adjustWidth items={[
                                    { key: "service", title: i18next.t("page.private.measure.summary.typeTypes.service") },
                                    { key: "construction", title: i18next.t("page.private.measure.summary.typeTypes.construction") },
                                    { key: "goods", title: i18next.t("page.private.measure.summary.typeTypes.goods") },
                                    { key: "other", title: i18next.t("page.private.measure.summary.typeTypes.other") }
                                ]} />
                            </GridElement>
                        </Grid>
                    </GridElement>
                </div>
            </Input.MutationProvider>}
            <Section bodyPadding="16px 0 0 0">
                <Section.Item title={i18next.t("page.private.measure.documents.sectionTitle")} icon="folder-tree">
                    {measure && <FileManager measureId={measure.id} title={i18next.t("page.private.measure.documents.tableTitle")} icon="folder-tree" scope="measure" onPathChange={measurePath => this.setState({ measurePath })} />}
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.tickets.sectionTitle")} icon="comments-alt" amountColor={this.state.tickets ? (this.state.tickets.length === 0 ? "rgb(169, 169, 169)" : (this.state.tickets.filter(ticket => ticket.status === "done" || ticket.archived).length < this.state.tickets.length) ? "#e67e22" : "#16a085") : "rgb(169, 169, 169)"} amount={this.state.tickets ? `${this.state.tickets.filter(ticket => ticket.status === "done" || ticket.archived).length} / ${this.state.tickets.length}` : 0}>
                    <GridElement styled title={i18next.t("page.private.measure.tickets.gridElementTitle")} icon="comments-alt" rightContent={<div>{i18next.t("page.private.measure.tickets.text1")} <b style={{ color: "#3b97d3" }}><i className="fa-duotone fa-comments-alt" /> {i18next.t("page.private.measure.tickets.text2")}</b> {i18next.t("page.private.measure.tickets.text3")}</div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.tickets.table.status"), width: "10%", column: "status" },
                                { title: i18next.t("page.private.measure.tickets.table.number"), width: "7.5%", column: "number" },
                                { title: i18next.t("page.private.measure.tickets.table.title"), width: "15%", column: "title" },
                                { title: i18next.t("page.private.measure.tickets.table.branch"), width: "20%", column: "subsidiary" },
                                { title: i18next.t("page.private.measure.tickets.table.source"), width: "12.5%", column: "channel" },
                                { title: i18next.t("page.private.measure.tickets.table.type"), width: "15%", column: "type" },
                                { title: i18next.t("page.private.measure.tickets.table.priority"), width: "12.5%", column: "priority" },
                                { title: i18next.t("page.private.measure.tickets.table.lastEdited"), width: "7.5%", column: "updatedAt" }
                            ]}
                            onItemClick={ticket => this.updateTicketModal.open(ticket.id)}
                            items={this.state.tickets ? this.state.tickets.sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()).map(ticket => ({
                                id: ticket.id,
                                number: ticket.number,
                                status: <div style={{ color: "white", background: getStatusAsTag(ticket.status).bgcolor , padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getStatusAsTag(ticket.status).title}</div>,
                                title: ticket.title,
                                subsidiary: ticket.subsidiary ? `${ticket.subsidiary.name} (${ticket.subsidiary.label}) / ${ticket.subsidiary.address.postalCode} ${ticket.subsidiary.address.city}` : null,
                                updatedAt: moment(ticket.updatedAt).fromNow(),
                                priority: <div style={{ color: "white", background: getPriorityAsTag(ticket.priority).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getPriorityAsTag(ticket.priority).title}</div>,
                                type: <div style={{ color: "white", background: getTypeAsTag(ticket.type.id, this.state.types).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getTypeAsTag(ticket.type.id, this.state.types).title}</div>,
                                channel: <div style={{ color: "white", background: getChannelAsTag(ticket.channel).bgcolor, padding: "4px 8px", borderRadius: "4px", display: "inline-block" }}>{getChannelAsTag(ticket.channel).title}</div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.quoteCreation.sectionTitle")} icon="comments-question" amountColor={this.state.offerRequests ? (this.state.offerRequests.length === 0 ? "rgb(169, 169, 169)" : (this.state.offerRequests.filter(or => !!or.measureOffer || or.cancelledAt).length < this.state.offerRequests.length) ? "#e67e22" : "#16a085") : "rgb(169, 169, 169)"} amount={this.state.offerRequests ? `${this.state.offerRequests.filter(or => !!or.measureOffer || or.cancelledAt).length} / ${this.state.offerRequests.length}` : 0}>
                    <GridElement styled title={i18next.t("page.private.measure.quoteCreation.gridElementTitle")} icon="comments-question" rightContent={<Button title={i18next.t("page.private.measure.quoteCreation.button")} icon="comments-question" thick onClick={() => this.createOfferRequestModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.quoteCreation.table.user"), width: "10%", column: "user" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.date"), width: "10%", column: "date" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.serviceProvider"), width: "15%", column: "serviceProvider" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.email"), width: "12.5%", column: "email" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.remindedAt"), width: "10%", column: "remindedAt" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.offer"), width: "12.5%", column: "offer" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.receivedAt"), width: "7.5%", column: "receivedAt" },
                                { title: i18next.t("page.private.measure.quoteCreation.table.title"), width: "22.5%", column: "title" }
                            ]}
                            contextItems={scanmetrix.checkPermission("MeasureOfferRequest") >= 3 ? [
                                {
                                    icon: "upload",
                                    title: i18next.t("page.private.measure.quoteCreation.offerUpload"),
                                    disabled: item => !!item.offerRaw || item.cancelledAt,
                                    onClick: data => {
                                        this.uploadOfferModal.open(data.id)
                                    }
                                },
                                {
                                    icon: "history",
                                    title: i18next.t("page.private.measure.quoteCreation.remindServiceProvider"),
                                    disabled: item => !!item.offerRaw || item.cancelledAt,
                                    onClick: data => {
                                        this.remindOfferRequestModal.open(data)
                                    }
                                },
                                {
                                    icon: "ban",
                                    title: i18next.t("page.private.measure.quoteCreation.cancelRequest"),
                                    disabled: item => !!item.offerRaw || item.cancelledAt,
                                    onClick: data => {
                                        this.cancelOfferRequestModal.open(data)
                                    }
                                }
                            ] : null}
                            onItemClick={offerRequest => this.showMeasureOfferRequestModal.open(offerRequest)}
                            items={this.state.offerRequests ? this.state.offerRequests.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(offerRequest => ({
                                ...offerRequest,
                                offerRaw: offerRequest.measureOffer,
                                remindedAt: offerRequest.remindedAt ? moment(offerRequest.remindedAt).format("DD.MM.YYYY HH:mm") : <div><i className="fa-duotone fa-times" /> {i18next.t("page.private.measure.quoteCreation.never")}</div>,
                                date: moment(offerRequest.createdAt).format("DD.MM.YYYY HH:mm"),
                                serviceProvider: offerRequest.serviceProvider.name,
                                offer: offerRequest.cancelledAt ? <div><i style={{ color: "#e74c3c" }} className="fa-duotone fa-ban" />{i18next.t("page.private.measure.quoteCreation.canceledOn")} {moment(offerRequest.cancelledAt).format("DD.MM.YYYY")}</div> : (offerRequest.measureOffer ? <div><i style={{ color: "#3b97d3" }} className="fa-duotone fa-pencil" />{offerRequest.measureOffer.number}</div> : <div><i style={{ color: "#3b97d3" }} className="fa-duotone fa-history" />{offerRequest.remindedAt ? i18next.t("page.private.measure.quoteCreation.reminded") : i18next.t("page.private.measure.quoteCreation.waitForOffer")}</div>),
                                receivedAt: offerRequest.receivedAt ? moment(offerRequest.receivedAt).format("DD.MM.YYYY") : null,
                                user: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : offerRequest.user.email} name={`${offerRequest.user.firstName} ${offerRequest.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {offerRequest.user.firstName} {offerRequest.user.lastName}
                                </div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.offer.sectionTitle")} icon="pencil" amountColor={this.state.offers ? (this.state.offers.length === 0 ? "rgb(169, 169, 169)" : (this.state.offers.filter(offer => offer.status === "accepted" || offer.status === "purchased" || offer.status === "declined").length < this.state.offers.length) ? "#e67e22" : "#16a085") : "rgb(169, 169, 169)"} amount={this.state.offers ? `${this.state.offers.filter(offer => offer.status === "accepted" || offer.status === "purchased" || offer.status === "declined").length} / ${this.state.offers.length}` : 0}>
                    <GridElement styled title={i18next.t("page.private.measure.offer.gridElementTitle")} icon="pencil" rightContent={<Button thick primary icon="file-upload" onClick={() => {
                        this.uploadOfferModal.open()
                    }} title="Angebot ohne vorherige Anfrage hochladen" />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.offer.table.user"), width: "10%", column: "user" },
                                { title: i18next.t("page.private.measure.offer.table.date"), width: "7.5%", column: "date" },
                                { title: i18next.t("page.private.measure.offer.table.status"), width: "12.5%", column: "status" },
                                { title: i18next.t("page.private.measure.offer.table.serviceProvider"), width: "12.5%", column: "serviceProvider" },
                                { title: i18next.t("page.private.measure.offer.table.number"), width: "10%", column: "number" },
                                { title: i18next.t("page.private.measure.offer.table.net"), width: "10%", column: "net" },
                                { title: i18next.t("page.private.measure.offer.table.gross"), width: "10%", column: "gross" },
                                { title: i18next.t("page.private.measure.offer.table.createdAt"), width: "7.5%", column: "createdAt" },
                                { title: i18next.t("page.private.measure.offer.table.file"), width: "12.5%", column: "name" },
                                { title: i18next.t("page.private.measure.offer.table.size"), width: "7.5%", column: "size" },
                            ]}
                            contextItems={scanmetrix.checkPermission("MeasureOffer") >= 3 ? [
                                {
                                    icon: "cog",
                                    title: i18next.t("page.private.measure.offer.changeStatus"),
                                    onClick: data => {
                                        this.updateMeasureOfferStatusModal.open({ id: data.id, status: data.statusRaw })
                                    }
                                },
                                {
                                    icon: "comment-alt-check",
                                    title: i18next.t("page.private.measure.offer.createOffer"),
                                    disabled: data => data.statusRaw !== "accepted",
                                    onClick: data => {
                                        this.createPurchaseOrderModal.open(data)
                                    }
                                },
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.measure.offer.deleteOffer"),
                                    onClick: data => {
                                        this.deleteMeasureOfferModal.open(data.id)
                                    }
                                }
                            ] : null}
                            onItemClick={offer => window.open(`${scanmetrix.backendURL}/measure-offer/pdf/${offer.id}`, "_blank")}
                            items={this.state.offers ? this.state.offers.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(offer => ({
                                ...offer,
                                raw: offer,
                                statusRaw: offer.status,
                                status: <><i style={{ color: getStatus(offer.status).color }} className={`fa-duotone fa-${getStatus(offer.status).icon}`} /> {getStatus(offer.status).title}</>,
                                size: prettyBytes(offer.size),
                                net: formatter.format(offer.net),
                                gross: formatter.format(offer.gross),
                                date: moment(offer.date).format("DD.MM.YYYY"),
                                serviceProvider: offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name,
                                createdAt: moment(offer.createdAt).format("DD.MM.YYYY"),
                                user: offer.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : offer.user.email} name={`${offer.user.firstName} ${offer.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {offer.user.firstName} {offer.user.lastName}
                                </div> : <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : offer.request.user.email} name={`${offer.request.user.firstName} ${offer.request.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {offer.request.user.firstName} {offer.request.user.lastName}
                                </div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.quoteReleased.sectionTitle")} amountColor={this.state.approvals ? (this.state.approvals.length === 0 ? "rgb(169, 169, 169)" : (this.state.approvals.filter(approval => approval.status === "purchased" || approval.status === "declined" || approval.status === "accepted" || approval.cancelledAt).length < this.state.approvals.length) ? "#e67e22" : "#16a085") : "rgb(169, 169, 169)"} amount={this.state.approvals ? `${this.state.approvals.filter(approval => approval.status === "purchased" || approval.status === "declined" || approval.status === "accepted" || approval.cancelledAt).length} / ${this.state.approvals.length}` : 0} icon="check-to-slot">
                    <GridElement styled title={i18next.t("page.private.measure.quoteReleased.gridElementTitle")} icon="check-to-slot" rightContent={<Button thick title={i18next.t("page.private.measure.quoteReleased.button")} icon="file-pdf" onClick={() => this.createApprovalModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.quoteReleased.table.user"), width: "10%", column: "user" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.createdAt"), width: "10%", column: "createdAt" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.email"), width: "12.5%", column: "email" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.status"), width: "12.5%", column: "status" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.approvedAt"), width: "7.5%", column: "approvedAt" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.proof"), width: "15%", column: "proof" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.net"), width: "10%", column: "net" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.fee"), width: "10%", column: "fee" },
                                { title: i18next.t("page.private.measure.quoteReleased.table.title"), width: "12.5%", column: "title" },
                            ]}
                            contextItems={[
                                {
                                    icon: "upload",
                                    title: i18next.t("page.private.measure.quoteReleased.confirmRelease"),
                                    disabled: item => scanmetrix.checkPermission("MeasureApproval") < 3 || item.statusRaw !== "approval" || item.cancelledAt,
                                    onClick: data => {
                                        this.approveMeasureApprovalModal.open(data.id)
                                    }
                                },
                                {
                                    icon: "file-pdf",
                                    title: i18next.t("page.private.measure.quoteReleased.releaseProof"),
                                    disabled: item => !item.name || item.cancelledAt,
                                    onClick: data => {
                                        window.open(`${scanmetrix.backendURL}/measure-approval-proof/pdf/${data.id}`, "_blank")
                                    }
                                },
                                {
                                    icon: "file-signature",
                                    title: i18next.t("page.private.measure.quoteReleased.releaseForm"),
                                    onClick: data => {
                                        window.open(`${scanmetrix.backendURL}/measure-approval/pdf/${data.id}`, "_blank")
                                    }
                                },
                                {
                                    icon: "ban",
                                    title: i18next.t("page.private.measure.quoteReleased.cancelRequest"),
                                    disabled: item => scanmetrix.checkPermission("MeasureApproval") < 3 || item.statusRaw !== "approval" || item.cancelledAt,
                                    onClick: data => {
                                        this.cancelMeasureApprovalModal.open(data)
                                    },
                                },
                                {
                                    icon: "trash",
                                    title: i18next.t("page.private.measure.quoteReleased.deleleRequest"),
                                    disabled: item => scanmetrix.checkPermission("MeasureApproval") < 4 || !item.cancelledAt,
                                    onClick: data => {
                                        this.deleteMeasureApprovalModal.open(data.id)
                                    }
                                }
                            ]}
                            onItemClick={approval => this.showMeasureApprovalModal.open(approval)}
                            items={this.state.approvals ? this.state.approvals.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()).map(approval => ({
                                ...approval,
                                statusRaw: approval.status,
                                fee: `${approval.fee} % (${formatter.format(approval.measureOffers.map(o => o.net).reduce((a, b) => a + b, 0) * (approval.fee / 100))})`,
                                net: formatter.format(approval.measureOffers.map(o => o.net).reduce((a, b) => a + b, 0)),
                                status: approval.cancelledAt ? <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-exclamation-circle" /> {i18next.t("page.private.measure.quoteReleased.canceledOn")} {moment(approval.cancelledAt).format("DD.MM.YYYY")}</> : <><i style={{ color: getStatus(approval.status).color }} className={`fa-duotone fa-${getStatus(approval.status).icon}`} /> {getStatus(approval.status).title}</>,
                                createdAt: moment(approval.createdAt).format("DD.MM.YYYY HH:mm"),
                                approvedAt: approval.cancelledAt ? "" : (approval.approvedAt ? moment(approval.approvedAt).format("DD.MM.YYYY") : i18next.t("page.private.measure.quoteReleased.createdAtNotYet")),
                                proof: approval.cancelledAt ? "" : (approval.approvedAt ? (approval.name || <div><i style={{ color: "#e74c3c" }} className="fa-duotone fa-exclamation-circle" /> {i18next.t("page.private.measure.quoteReleased.withoutProof")}</div>) : i18next.t("page.private.measure.quoteReleased.noneYet")),
                                user: <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : approval.user.email} name={`${approval.user.firstName} ${approval.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {approval.user.firstName} {approval.user.lastName}
                                </div>
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.orders.sectionTitle")} icon="comment-alt-check" amountColor={this.state.purchaseOrders ? (this.state.purchaseOrders.length === 0 ? "rgb(169, 169, 169)" : (this.state.purchaseOrders.filter(purchaseOrder => purchaseOrder.status === "done").length < this.state.purchaseOrders.length) ? "#e67e22" : "#16a085") : "rgb(169, 169, 169)"} amount={this.state.purchaseOrders ? `${this.state.purchaseOrders.filter(purchaseOrder => purchaseOrder.status === "done").length} / ${this.state.purchaseOrders.length}` : 0}>
                    <GridElement styled title={i18next.t("page.private.measure.orders.gridElementTitle")} icon="comment-alt-check" rightContent={<Button onClick={() => this.createManualPurchaseOrderModal.open()} thick icon="comment-alt-check" title={i18next.t("page.private.measure.orders.createPurchaseOrder")} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.orders.table.date"), width: "7.5%", column: "date" },
                                { title: i18next.t("page.private.measure.orders.table.status"), width: "15%", column: "status" },
                                { title: i18next.t("page.private.measure.orders.table.number"), width: "7.5%", column: "number" },
                                { title: i18next.t("page.private.measure.orders.table.letterHead"), width: "7.5%", column: "letterHead" },
                                { title: i18next.t("page.private.measure.orders.table.type"), width: "12.5%", column: "type" },
                                { title: i18next.t("page.private.measure.orders.table.subsidiary"), width: "15%", column: "subsidiary" },
                                { title: i18next.t("page.private.measure.orders.table.serviceProvider"), width: "12.5%", column: "serviceProvider" },
                                { title: i18next.t("page.private.measure.orders.table.title"), width: "15%", column: "title" },
                                { title: i18next.t("page.private.measure.orders.table.sum"), width: "7.5%", column: "sum" },
                            ]}
                            onItemClick={purchaseOrder => this.props.history.push(`/purchaseorder/${purchaseOrder.id}`)}
                            items={this.state.purchaseOrders ? this.state.purchaseOrders.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(purchaseOrder => {
                                return ({
                                    ...purchaseOrder,
                                    status: <><i className={`fa-duotone fa-${getPOStatus(purchaseOrder.status).icon}`} style={{ color: getPOStatus(purchaseOrder.status).color, marginRight: 8 }} /> {getPOStatus(purchaseOrder.status).title}</>,
                                    type: <><i className={`fa-duotone fa-${getPOType(purchaseOrder.type).icon}`} style={{ color: getPOType(purchaseOrder.type).color, marginRight: 8 }} /> {getPOType(purchaseOrder.type).title}</>,
                                    letterHead: purchaseOrder.letterHead ? purchaseOrder.letterHead.name : null,
                                    serviceProvider: purchaseOrder.serviceProvider.name,
                                    date: moment(purchaseOrder.date).format("DD.MM.YYYY"),
                                    subsidiary: purchaseOrder.subsidiary.name + (purchaseOrder.subsidiary.label ? (" (" + purchaseOrder.subsidiary.label + ")") : ""),
                                    sum: formatter.format(((typeof purchaseOrder.positions === "string" ? JSON.parse(purchaseOrder.positions) : purchaseOrder.positions) || []).map(item => item.amount * item.price).reduce((a, b) => a + b, 0))
                                })
                            }) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.invoice.sectionElementTitle")} icon="coins" amount={this.state.accountingInvoices ? this.state.accountingInvoices.length : 0}>
                    <GridElement styled icon="coins" title={i18next.t("page.private.measure.invoice.gridElementTitle")} rightContent={<div>{i18next.t("page.private.measure.invoice.text1")} <b style={{ color: "#3b97d3" }}><i className="fa-duotone fa-coins" /> {i18next.t("page.private.measure.invoice.text2")}</b> {i18next.t("page.private.measure.invoice.text3")} <b style={{ color: "#3b97d3" }}><i className="fa-duotone fa-comment-alt-check" /> {i18next.t("page.private.measure.invoice.text4")}</b> {i18next.t("page.private.measure.invoice.text5")}</div>}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.invoice.table.number"), width: "12.5%", column: "number" },
                                { title: i18next.t("page.private.measure.invoice.table.date"), width: "10%", column: "date" },
                                { title: i18next.t("page.private.measure.invoice.table.serviceProvider"), width: "15%", column: "serviceProvider" },
                                { title: i18next.t("page.private.measure.invoice.table.net"), width: "10%", column: "net" },
                                { title: i18next.t("page.private.measure.invoice.table.gross"), width: "10%", column: "gross" },
                                { title: i18next.t("page.private.measure.invoice.table.branch"), width: "17.5%", column: "subsidiary" },
                                { title: i18next.t("page.private.measure.invoice.table.purchaseOrder"), width: "12.5%", column: "purchaseOrder" },
                                { title: i18next.t("page.private.measure.invoice.table.contract"), width: "12.5%", column: "contract" }
                            ]}
                            onItemClick={item => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + item.data.accountingInvoiceDocument.id, "_blank")}
                            items={this.state.accountingInvoices ? this.state.accountingInvoices.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(accountingInvoice => ({
                                ...accountingInvoice,
                                data: accountingInvoice,
                                net: formatter.format(accountingInvoice.net),
                                gross: formatter.format(accountingInvoice.gross),
                                date: moment(accountingInvoice.date).format("DD.MM.YYYY"),
                                serviceProvider: accountingInvoice.serviceProvider.name,
                                subsidiary: accountingInvoice.subsidiary ? (accountingInvoice.subsidiary.name + (accountingInvoice.subsidiary.label ? (" (" + accountingInvoice.subsidiary.label + ")") : "")) : null,
                                purchaseOrder: accountingInvoice.purchaseOrder ? `${accountingInvoice.purchaseOrder.number} / ${moment(accountingInvoice.purchaseOrder.date).format("DD.MM.YYYY")}` : null,
                                contract: accountingInvoice.contract ? `${accountingInvoice.contract.title} ${accountingInvoice.contract.number ? (" (" + accountingInvoice.contract.number + ")") : ""}` : null,
                                number: <div style={{ paddingLeft: "16px" }}><Status status={accountingInvoice.declined ? false : true}><i className={`far fa-${accountingInvoice.declined ? "times" : "check"}`} /></Status>{accountingInvoice.number}</div>,
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.measure.appointments.sectionElementTitle")} icon="calendar" amount={this.state.appointments ? this.state.appointments.length : 0}>
                    <GridElement styled icon="calendar" title={i18next.t("page.private.measure.appointments.gridElementTitle")} rightContent={<Button thick icon="calendar" onClick={() => this.createAppointmentModal.open()} title={i18next.t("page.private.measure.appointments.createAppointment")} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.measure.appointments.table.start"), width: "10%", column: "start" },
                                { title: i18next.t("page.private.measure.appointments.table.end"), width: "10%", column: "end" },
                                { title: i18next.t("page.private.measure.appointments.table.name"), width: "20%", column: "name" },
                                { title: i18next.t("page.private.measure.appointments.table.description"), width: "25%", column: "description" },
                                { title: i18next.t("page.private.measure.appointments.table.allDay"), width: "10%", column: "allDay" },
                                { title: i18next.t("page.private.measure.appointments.table.user"), width: "15%", column: "user" },
                                { title: i18next.t("page.private.measure.appointments.table.createdAt"), width: "10%", column: "createdAt" }
                            ]}
                            onItemClick={item => this.updateAppointmentModal.open({ ...item.raw, title: item.name, onlyMeta: true })}
                            items={this.state.appointments ? this.state.appointments.sort((a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()).map(appointment => ({
                                ...appointment,
                                raw: appointment,
                                start: moment(appointment.date).format("DD.MM.YYYY HH:mm"),
                                end: moment(appointment.date).add(appointment.duration, "minutes").format("DD.MM.YYYY HH:mm"),
                                allDay: appointment.allDay ? <><i className="fa-duotone green fa-check-circle"/> Ja</> : <><i className="fa-duotone fa-times-circle"/> Nein</>,
                                createdAt: moment(appointment.createdAt).fromNow(),
                                user: appointment.user ? <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ width: 32, height: 32, marginRight: 8, borderRadius: 32, overflow: "hidden" }}>
                                        <Avatar size="32" email={scanmetrix.disableGravatar ? null : appointment.user.email} name={`${appointment.user.firstName} ${appointment.user.lastName}`} className="avatarInner" />
                                    </div>
                                    {appointment.user.firstName} {appointment.user.lastName}
                                </div> : null
                            })) : null}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </>
    }
}


const Status = styled.div`
    position: absolute;
    left: -16px;
    width: 32px;
    height: 32px;
    background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 16px);
    font-size: 0.9em;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);

    i {
        margin: 0 !important;
    }
`


class CreateAppointmentModal extends Component {
    state = { name: null, subsidiaryId: null, subsidiaries: [], filteredContractIds: [], description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, contractId: null, contractProvisionIntervalId: null, contracts: [], intervals: [], loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Contracts {
                        nodes {
                            id
                            title
                            number
                            partnerName
                            serviceProvider {
                                id
                                name
                            }
                        }
                    }
                    Subsidiaries {
                        nodes {
                            id
                            name
                            label
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ contracts: data.data.Contracts.nodes, subsidiaries: data.data.Subsidiaries.nodes })
        })
    }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={() => this.setState({ name: null, description: null, startDate: null, startTime: null, endDate: null, endTime: null, allDay: false, loading: false })}>
            <Modal.Head title={i18next.t("page.private.calendar.legendForm.title")} icon="calendar-day" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridGap: "16px", gridTemplateColumns: "100%" }}>
                    <InputGrid allDay={this.state.allDay}>
                        <Input date value={this.state.startDate} onChange={startDate => {
                            if(this.state.endDate) this.setState({ startDate })
                            else this.setState({ startDate, endDate: startDate })
                        }} required icon="calendar-day" label={i18next.t("page.private.calendar.legendForm.startDate")} adjustWidth />
                        {!this.state.allDay && <Input required time value={this.state.startTime} onChange={startTime => this.setState({ startTime })} icon="clock" label={i18next.t("page.private.calendar.legendForm.startTime")} adjustWidth />}
                    </InputGrid>
                    <InputGrid allDay={this.state.allDay}>
                        <Input date value={this.state.endDate} onChange={endDate => {
                            if(this.state.startDate) this.setState({ endDate })
                            else this.setState({ endDate, startDate: endDate })
                        }} required icon="calendar-day" label={i18next.t("page.private.calendar.legendForm.endDate")} adjustWidth />
                        {!this.state.allDay && <Input required time value={this.state.endTime} onChange={endTime => this.setState({ endTime })} icon="clock" label={i18next.t("page.private.calendar.legendForm.endTime")} adjustWidth />}
                    </InputGrid>
                    <Checkbox label={i18next.t("page.private.calendar.legendForm.allDay")} value={this.state.allDay} onChange={allDay => this.setState({ allDay })} />
                    <Input required value={this.state.name} onChange={name => this.setState({ name })} icon="pencil" label={i18next.t("page.private.calendar.legendForm.name")} adjustWidth />
                    <Input textArea value={this.state.description} onChange={description => this.setState({ description })} icon="font" label={i18next.t("page.private.calendar.legendForm.description")} adjustWidth />
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick disabled={!this.state.name || !this.state.startDate || !this.state.endDate || (!this.state.allDay && (!this.state.endDate || !this.state.endTime || !this.state.startTime))} title={i18next.t("page.private.calendar.legendForm.exitButton")} icon="calendar-day" onClick={() => {
                this.setState({ loading: true })

                scanmetrix.client.mutate({
                    mutation: scanmetrix.gql`
                        mutation($name: String!, $description: String, $type: String!, $duration: Int!, $date: DateTime!, $allDay: Boolean!, $subsidiaryId: ID, $measureId: ID) {
                            createAppointment(name: $name, description: $description, type: $type, date: $date, duration: $duration, allDay: $allDay, subsidiaryId: $subsidiaryId, measureId: $measureId)
                        }
                    `,
                    variables: {
                        name: this.state.name,
                        description: this.state.description,
                        subsidiaryId: this.props.subsidiaryId,
                        measureId: this.props.measureId,
                        type: "custom",
                        date: moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm").toDate(),
                        duration: this.state.allDay ? (moment.duration(moment(this.state.endDate, "DD.MM.YYYY").diff(moment(this.state.startDate, "DD.MM.YYYY"))).asMinutes()) : (moment.duration(moment(`${this.state.endDate} ${this.state.endTime}`, "DD.MM.YYYY HH:mm").diff(moment(`${this.state.startDate} ${this.state.startTime}`, "DD.MM.YYYY HH:mm"))).asMinutes()),
                        allDay: this.state.allDay
                    }
                }).then(result => {
                    this.setState({ loading: false })

                    if(result.data.createAppointment) {
                        this.props.refresh()
                        instance.close()
                    }
                })}
            } />} />
        </Modal>
    }
}



const InputGrid = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: ${props => props.allDay ? "5fr" : "3fr 2fr"};
`
